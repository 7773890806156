
.product-card-border {
    border: 1px solid rgb(66, 66, 66);
    box-shadow: 2px 2px rgba(104, 104, 104, 0.699);
}


@media screen and (max-width: 768px) {
    .card-combos {
        width: 100%!important;
        margin-bottom: 5%;
        border: 1px solid rgba(184, 184, 184, 0.727); 
    }
}

@media screen and (min-width: 769px) {
    .card-combos {
        width: 16rem!important;
        margin: 2%;
    }
}

.combo-image:hover {
    cursor: zoom-in;
    z-index: 10;
}