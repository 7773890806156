.loading-screen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    z-index: 1;
    background-color: rgba(0, 0, 0, 0.535);

}


.loading-screen > img {
    margin-top: 20%;
}