.calendar-container {
    padding: 0 35px;
}

.radio-container {
    margin-bottom: 8px;
    font-size: 16px;
}

.card-bg-color {
    /* background-color: #ffc375fa; */
    background-image: linear-gradient(#ffc375fa, 40%, rgb(252, 244, 212));
    border: none
}

@media screen and (max-width: 768px) {
    .card-sizer {
        width: 100%!important;
    }
}

@media screen and (min-width: 769px) {
    .card-sizer {
        width: 75%!important;
    }
}