

.btn-qnt { }
.btn-qnt:hover {
    font-size: 27px!important;
    color: red;
    cursor: pointer;
}

.disabled-link {
    pointer-events: none;
    opacity: 0.5;
}